import React, { useState } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import 'swiper/swiper-bundle.min.css';
import axios from "axios";
import { useCookies } from "react-cookie";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useForm, Controller } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import cs from "date-fns/locale/cs"; // the locale you want
registerLocale("cs", cs); // register it with the name you want



const VytvoritNoveInner = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 770px) {
    display: block;
  }
  
  .left {
    width: calc(50% + 150px);
    height: calc(100vh - 65px);
    position: relative;
    min-width: 700px;

    @media (max-width: 1200px) {
      min-width: 0;
      width: calc(500px);
    }

    @media (max-width: 770px) {
      width: calc(100% - 50px);
      margin: 0 auto;
    }

    .formWrapper {
      max-width: 500px;
      margin-left: calc((50vw - 1150px / 2) + 10px);
      margin-top: 60px;
      padding-bottom: 90px;

      @media (max-width: 1200px) {
        margin-left: 50px;
      }

      @media (max-width: 770px) {
        margin: 0 auto;
        margin-top: 40px;
      }

      h1 {
        font-family: NY-Medium;
        font-size: 32px;
        color: #000000;
        margin-bottom: 25px;

        @media (max-width: 490px) {
          font-size: 27px;
          margin-bottom: 20px;
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #4D4D4D;
        line-height: 25px;
        margin-bottom: 50px;

        @media (max-width: 490px) {
          margin-bottom: 35px;
        }
      }

      form {
        .formInner {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          @media (max-width: 490px) {
            display: block;
          }

          .pole {
            width: calc(50% - 20px);

            @media (max-width: 490px) {
              width: 100%;
            }
          }
          
          .react-datepicker-wrapper {
            width: 100%;
          }
        }

        .pole {
          margin-top: 25px;
          display: block;
          width: calc(100%);

          .label {
            display: block;
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #6C6C6C;
            line-height: 25px;
            margin-bottom: 8px;
          }

          .textInput {
            display: block;
            width: calc(100% - 24px);
            background: #FFFFFF;
            border: 2px solid #D2D2D2;
            border-radius: 5px;
            padding: 10px;
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #000;
            line-height: 25px;

            &:focus {
              border: 2px solid #566FEF;
            }
          }
        }

        .inputError {
          .textInput {
            border: 2px solid #FF6060 !important;
          }

          .label {
            color: #FF6060;
          }
        }
      }

      .errorMsg {
        color: #FF6060;
        margin-top: 10px;
      }
    }

    .submitButton {
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      background: #566FEF;
      border-radius: 5px;
      width: 240px;
      height: 50px;
      border: 0;
      border-radius: 5px;
      margin-top: 50px;
      cursor: pointer;
    }

    .gdpr {
      display: block;
      font-family: Visuelt-Regular  !important;
      font-size: 14px  !important;
      color: #6C6C6C  !important;
      margin-top: 25px;

      a {
        color: #6C6C6C;
      }
    }
  }

  .right {
    width: calc(50% - 150px);
    position: relative;

    @media (max-width: 1200px) {
      width: calc(100% - 550px);
    }

    @media (max-width: 770px) {
      display: none;
    }

    .fotka {
      height: calc(100vh - 65px);
    }
  }
`;

const VytvoritNove = ({ data }) => {
  const { register, handleSubmit, errors, control } = useForm();
  const onSubmit = data => handleForm(data);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");


  const [cookies, setCookie, removeCookie] = useCookies(['session_id']);

  
  const handleForm = (data) => {
    const { name, city, deathDate, birthDate } = data;

    setIsLoading(true);

    if ((deathDate - birthDate) > 0) {
      setErrorMsg("");

      let parteData = {
        "name": name,
        "city": city,
        "deathDate": deathDate,
        "birthDate": birthDate,
        "published": false,
        "count": undefined,
      }

      let backendUrl = "";

      if (process.env.NODE_ENV === 'development') {
        backendUrl = 'http://localhost:1337'
      }
      
      if (process.env.NODE_ENV === 'production') {
        backendUrl = 'https://goodbye-backend.herokuapp.com'
      }

      axios.post(`${backendUrl}/auth/local`, {
        identifier: 'app@goodbye.cz',
        password: 'Good.Bye123',
      }).then((res) => {
        const jwt = res.data.jwt;

        axios.get(`${backendUrl}/partes/count`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          },
        }).then((res) => {

          parteData.count = res.data;

          axios.post(`${backendUrl}/partes?_limit=-1`, parteData, {
            headers: {
              Authorization: `Bearer ${jwt}`
            },
          }).then((res) => {
            setCookie("session_id", res.data.id, { path: "/" });
            navigate("/vytvorit-parte/donio/");
            setIsLoading(false);
          });
        });
      });
    }

    else {
      setErrorMsg("Datum smrti nemůže být dřív, než datum narození");
      setIsLoading(false);
    }
  }


  return (
    <StaticQuery
      query = {graphql`
        query {
          image: file(relativePath: { eq: "parteImage.png" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <VytvoritNoveInner>
          <div className="left">
            <div className="formWrapper">
              <h1>Tvorba online parte</h1>
              <p>Jako první nám sdělte pár základních infomací o zesnulém</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label className={`pole ${errors.name && "inputError"}`}>
                  <span className="label" >Jméno zesnulého</span>
                  <input className="textInput"  ref={register({ required: true })} name="name" type="text" />
                  {errors.name && <div className="errorMsg">Toto pole je povinné</div>}
                </label>
                
                <div className="formInner">
                  <label className={`pole ${errors.birthDate && "inputError"}`}>
                    <span className="label" >Datum narození zesnulého</span>

                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="birthDate"
                      defaultValue=""
                      render={(props) => (
                        <ReactDatePicker
                          placeholderText="Vyberte datum"
                          onChange={(e) => props.onChange(e)}
                          selected={props.value}
                          locale="cs"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          className="textInput"
                          dropdownMode="select"
                          dateFormat="dd. MM. yyyy"
                        />
                      )}
                    />

                    {errors.birthDate && <div className="errorMsg">Toto pole je povinné</div>}
                  </label>

                  <label className={`pole ${errors.deathDate && "inputError"}`}>
                    <span className="label" >Datum úmrtí</span>

                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="deathDate"
                      defaultValue=""
                      render={(props) => (
                        <ReactDatePicker
                          placeholderText="Vyberte datum"
                          onChange={(e) => props.onChange(e)}
                          selected={props.value}
                          locale="cs"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          className="textInput"
                          dropdownMode="select"
                          dateFormat="dd. MM. yyyy"
                        />
                      )}
                    />

                    {errors.deathDate && <div className="errorMsg">Toto pole je povinné</div>}
                  </label>
                </div>

                {errorMsg && <div className="errorMsg">{errorMsg}</div>}


                <label className={`pole ${errors.city && "inputError"}`}>
                  <span className="label" >Město</span>
                  <input className="textInput" ref={register({ required: true })} name="city" type="text" />
                  {errors.city && <div className="errorMsg">Toto pole je povinné</div>}

                  {/* Tady přidat auto suggestion */}
                </label>


                <input className="submitButton" type="submit" value={(isLoading) ? "Načítání..." : "Další"} />

                <p className="gdpr">Kliknutím na tlačítko “Další” souhlasíte se <a target="_blank" href="/parte/gdpr/">zpracováním osobních údajů</a>.</p>

              </form>
            </div>
          </div>
          <div className="right">
            <Img className="fotka" alt="" fluid={data.image.childImageSharp.fluid} />
          </div>
        </VytvoritNoveInner>
      )}
    />
  );
};


export default VytvoritNove;
