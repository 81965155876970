import React, { useCallback, useEffect, useState, useRef } from "react";
import { navigate } from "gatsby";
import 'swiper/swiper-bundle.min.css';
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';
import ProgressBar from "../../components/parte/ProgressBar";
import { useDropzone } from 'react-dropzone';
import { useCookies } from "react-cookie";
import axios from "axios";
import ImageIcon from "../../assets/img/imageIcon.svg";
import PretahnoutFotky from "./PretahnoutFotky";
import ParteModal from "./parteModal";
import UpravitInfoModal from "./UpravitInfoModal";
import OsobniInfoModal from "./OsobniInfoModal";
import PohrebInfoModal from "./PohrebInfoModal";
import DonioInfoModal from "./DonioInfoModal";
import VymazatParteModal from "./VymazatParteModal";
import Xko from "../../assets/img/zavrit.svg";
import Loading from "../../assets/img/loadingTransparent.svg";



const ObrazekGalerieInner = styled.div`
  position: relative;
  width: 100%;
  height: 120px;  

  .innerPic {
    background: url(${props => props.imageUrl});
    background-position: center;
    background-repeat: no-repeat;
    min-width: calc(100% + 2px);
    min-height: calc(100% + 2px);
    background-size: cover;
    position: absolute;
    left: -1px;
    top: -1px;
    border-radius: 5px;
  }

  .loadingInnerPic {
    background: #e6e6e6;
    background-size: 30%;
    background-image: url(${props => props.imageUrl});
    background-position: center;
    background-repeat: no-repeat;
  }

  button {
    position: absolute;
    z-index: 10;
    border-radius: 100%;
    border: 0;
    background: #fff;
    width: 30px;
    height: 30px;
    right: -10px;
    top: -10px;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
    transition: 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.25);
    }

    img {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 9px;
      left: 9px;
    }
  }
`;

const VnitniObrazek = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: url(${props => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
`;

const UpravitInner = styled.section`
  min-height: calc(100vh - 65px);


  .loadingScreen {
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .obsahWrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 50px;

    @media (max-width: 580px) {
      margin-top: 35px;
    }
    
    .upravitButton {
      background: #FFFFFF;
      border: 2px solid #000000;
      border-radius: 5px;
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #000000;
      text-align: center;
      padding: 13px 0;
      width: 180px;
      cursor: pointer;

      @media (max-width: 580px) {
        padding: 11px 0;
        width: 130px;
      }

      &:hover {
        background: #f5f5f5;
      }
    }

    .saveButton {
      font-family: Visuelt-Medium;
      font-size: 18px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      background: #3A557C;
      border-radius: 5px;
      display: block;
      width: 240px;
      padding: 14px 0;
      margin: 0 auto;
      margin-right: 0;
      border: 0;
      margin-bottom: 40px;

      @media (max-width: 1100px) {
        margin: 0;
        margin-bottom: 40px;
        width: 180px;
        padding: 11px 0;
      }

      &:hover {
        cursor: pointer;
        background: #2A4164;
      }
    }


    .obsahInner {
      .mobileShare {
        display: none;
      }

      .basicInfo {
        width: calc(100% - 100px);
        max-width: 1150px;
        margin: 0 auto;

        @media (max-width: 900px) {
          width: calc(100% - 100px);
        }

        @media (max-width: 580px) {
          width: calc(100% - 50px);
        }

        .errorMessage {
          font-family: Visuelt-Medium;
          font-size: 16px;
          color: #FF6060;
          text-decoration: none;
          border: 2px solid #FF6060;
          border-radius: 5px;
          padding: 20px;
          max-width: 630px;
          margin-bottom: 50px;
          
          @media (max-width: 1200px) {
            width: calc(100% - 350px - 80px);
          }

          @media (max-width: 1000px) {
            max-width: none;
            width: calc(100% - 44px);
          }

          @media (max-width: 580px) {
          }
        }
      }

      .profile {
        display: flex;
        align-items: center;
        margin-bottom: 70px;
        max-width: 630px;
        position: relative;

        @media (max-width: 1200px) {
          width: calc(100% - 350px - 80px);
        }

        @media (max-width: 1000px) {
          max-width: none;
          width: 100%;
        }

        @media (max-width: 580px) {
          display: block;
          margin-bottom: 40px;
        }

        .removePic {
          position: absolute;
          z-index: 10;
          border-radius: 100%;
          border: 0;
          background: #fff;
          width: 50px;
          height: 50px;
          right: 0;
          top: 0;
          box-shadow: 0 5px 10px 0 rgba(0,0,0,0.15);
          transition: 0.1s ease-in-out;

          &:hover {
            cursor: pointer;
            transform: scale(1.2);
            box-shadow: 0 5px 10px 0 rgba(0,0,0,0.25);
          }

          img {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 17px;
            left: 17px;
          }
        }

        .obrazekReadyTodo {
          cursor: pointer;
        }

        .obrazek {
          border-radius: 100%;
          margin-right: 50px;
          background: #F7F7F7;
          width: 200px;
          height: 200px;
          position: relative;
          max-width: 100%;
          max-height: 100%;

          @media (max-width: 580px) {
            margin-bottom: 30px;
            width: 180px;
            height: 180px;
          }

          &:hover {
            background: #dedede;
          }

          .vnitniObrazek {
            
          }

          .loadingPic {
            background-size: 50%;
          }

          div {
            height: 100%;
            width: 100%;
            text-align: center;

            .obrazekInner {
              height: auto;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);

              img {
                display: block;
                margin: 0 auto;
                margin-bottom: 15px;
              }

              p {
                display: block;
                margin: 0 auto;
                width: 160px;
                font-family: Visuelt-Regular;
                font-size: 16px;
                color: #515151;
                text-align: center;

                span {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .text {
          div {
            margin-bottom: 15px;

            h1 {
              font-family: Visuelt-Medium;
              font-size: 24px;
              color: #000000;
              line-height: 25px;
              display: inline-block;
            }

            span {
              font-family: Visuelt-Regular;
              font-size: 16px;
              color: #7C7C7C;
              text-align: center;
              line-height: 25px;
              margin-left: 10px;
            }
          }

          p {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #000000;
            line-height: 25px;
          }
        }

        .upravitButton {
          margin-top: 20px;
        }
      }

      .textSection {
        max-width: 630px;
        margin-bottom: 80px;
        background: #FFFFFF;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='3' stroke-dasharray='5' stroke-dashoffset='95' stroke-linecap='butt'/%3e%3c/svg%3e");
        border-radius: 5px;
        height: 250px !important;
        position: relative;
        padding: 20px;

        @media (max-width: 1200px) {
          width: calc(100% - 350px - 80px);
        }

        @media (max-width: 1000px) {
          max-width: none;
          width: calc(100% - 40px);
        }

        @media (max-width: 580px) {
          margin-bottom: 40px;
        }

        textarea {
          height: 210px !important;
          resize: none;
          width: calc(100%) !important;
          margin: 0 auto !important;
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #000000;
          line-height: 25px;
          border: 0;
          display: block;
        }

        span {
          position: absolute;
          bottom: 20px;
          left: 20px;
        }
      }

      .grayZone {
        background: #F7F7F7;
        width: 100%;
        padding-top: 60px;
        padding-bottom: 80px;

        @media (max-width: 1000px) {
          padding-bottom: 60px;
        }

        @media (max-width: 580px) {
          padding-bottom: 40px;
          padding-top: 40px;
        }

        .vymazatParte {
          background: #FF6060;
          color: #fff;
          font-family: Visuelt-Medium;
          text-align: center;
          border: 2px solid #FF6060;
          border-radius: 5px;
          font-size: 18px;
          padding: 13px 0;
          width: 180px;
          cursor: pointer;
          margin-left: 25px;

          @media (max-width: 390px) {
            margin-left: 0px;
            display: block;
            margin-top: 15px;
          }

          &:hover {
            background: #da4646;
            border: 2px solid #da4646;
          }
        }

        .grayZoneInner {
          width: calc(100% - 100px);
          max-width: 1150px;
          margin: 0 auto;

          @media (max-width: 1000px) {
            max-width: none;
          }

          @media (max-width: 580px) {
            width: calc(100% - 50px);
          }
        }

        .galerie {
          max-width: calc(630px + 40px);

          @media (max-width: 1200px) {
            width: calc(100% - 350px - 40px);
          }

          @media (max-width: 1000px) {
            max-width: none;
            width: calc(100%);
          }

          h2 {
            font-family: Visuelt-Medium;
            font-size: 22px;
            color: #000000;
            line-height: 25px;
            margin-bottom: 30px;

            @media (max-width: 580px) {
              font-size: 20px;
              line-height: 29px;
            }
          }

          .galerieInner {
            margin-bottom: 80px;

            .galerieInnerWrap {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              gap: 30px 30px;

              @media (max-width: 580px) {
                grid-template-columns: 1fr 1fr;
                margin-top: 30px;
              }

              @media (max-width: 320px) {
                grid-template-columns: 1fr;
              }
            } 

            .obrazek {
              
            }

            .fotka {
              max-width: 200px;

              .obrazek {
                border-radius: 5px;
              }
            }
          }
        }

        .funeralInfo {
          max-width: 630px;
          margin-top: 0px;

          @media (max-width: 1200px) {
            width: calc(100% - 350px - 40px);
          }

          @media (max-width: 1000px) {
            max-width: none;
            width: calc(100%);
          }

          @media (max-width: 580px) {
            margin-top: 40px;
          }

          h2 {
            font-family: Visuelt-Medium;
            font-size: 22px;
            color: #000000;
            line-height: 25px;
            margin-bottom: 30px;

            @media (max-width: 580px) {
              font-size: 20px;
              line-height: 29px;
            }
          }

          .text {
            display: flex;
            margin-bottom: 40px;

            @media (max-width: 580px) {
              display: block;
            }

            .udaj {
              margin-right: 50px;

              @media (max-width: 580px) {
                margin: 0;
                margin-bottom: 20px;
              }

              span {
                font-family: Visuelt-Bold;
                font-size: 14px;
                text-transform: uppercase;
                color: #828282;
                line-height: 25px;
                display: block;
                margin-bottom: 5px;
              }

              div {
                font-family: Visuelt-Regular;
                font-size: 20px;
                color: #000000;
                line-height: 25px;
                display: block;
              }
            }
          }
        }
      }

      .zedVzpominek {
        max-width: 630px;
        margin-left: calc((100% - 1150px)/2);
        margin-top: 70px;

        h2 {
          font-family: Visuelt-Medium;
          font-size: 22px;
          color: #000000;
          line-height: 25px;
          margin-bottom: 30px;
        }

        .zedVzpominekInner {
          .input {
            background: #FFFFFF;
            border: 1px solid #C8C8C8;
            border-radius: 5px;
            padding: 30px;

            textarea {
              width: 100%;
              resize: none;
              height: 130px;
              margin-bottom: 20px;
              font-family: Visuelt-Regular;
              font-size: 16px;
              color: #000000;
              line-height: 25px;
              border: 0;
            }

            button {
              font-family: Visuelt-Medium;
              font-size: 16px;
              color: #FFFFFF;
              text-align: center;
              background: #3A557C;
              border: 0;
              padding: 10px 0;
              width: 120px;
              border-radius: 5px;
              margin-left: auto;
              display: block;
            }
          }

          .posts {
            .post {
              background: #FFFFFF;
              border: 1px solid #C8C8C8;
              border-radius: 5px;
              padding: 30px;
              margin-top: 20px;

              p {
                font-family: Visuelt-Regular;
                font-size: 16px;
                color: #000000;
                line-height: 25px;
              }

              .bot {
                border-top: 1px solid #D8D8D8;
                margin-top: 15px;
                padding-top: 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                div {
                  font-family: Visuelt-Medium;
                  font-size: 16px;
                  color: #000000;
                  line-height: 25px;

                  span {
                    font-family: Visuelt-Regular;
                    color: #939393;
                  }
                }

                button {
                  font-family: Visuelt-Regular;
                  font-size: 16px;
                  color: #B0B0B0;
                  text-align: right;
                  line-height: 25px;
                  text-decoration: underline;
                  border: 0;
                  background: 0;
                  padding: 0;
                }
              }
            }
          }
        } 
      }
    }

    .rightPanel {
      max-width: 370px;
      position: absolute;
      top: 0;
      right: calc((100% - 1150px)/2);

      @media (max-width: 1200px) {
        right: 50px;
      }

      @media (max-width: 1000px) {
        position: static;
        margin: 0 auto;
        width: calc(100% - 100px);
        max-width: none;
        margin-top: 60px;
      }

      @media (max-width: 580px) {
        width: calc(100% - 50px);
        margin-top: 40px;
      }

      .saveButton {

      }

      .box {
        background: #FFFFFF;
        border: 1px solid #C8C8C8;
        border-radius: 5px;
        padding: 25px;
        margin-bottom: 40px;

        h2 {
          font-family: Visuelt-Medium;
          font-size: 22px;
          color: #000000;
          line-height: 32px;

          @media (max-width: 580px) {
            font-size: 20px;
            line-height: 29px;
          }
        }

        .boxInner {
          border-top: 1px solid #D8D8D8;
          padding-top: 30px;
          margin-top: 15px;
        }
      }

      .donioBox {

        .loadingDonio {
          width: 150px;
          margin: 0 auto;
          display: block;
        }


        .donioPic {
          height: 140px;
          overflow: hidden;
          border-radius: 5px;
        }

        h3 {
          font-family: NY-Medium;
          font-size: 20px;
          color: #000000;
          line-height: 27px;
          margin: 25px auto;
        }

        .progressBar {
          margin-bottom: 15px;
        }

        .vybrano {
          font-family: Visuelt-Bold;
          font-size: 18px;
          color: #000000;
        }

        .odDarcu {
          font-family: Visuelt-Medium;
          font-size: 16px;
          color: #9E9E9E;
          margin-top: 7px;
          margin-bottom: 30px;
        }

        a {
          background: #e66e3b;
          width: 100%;
          padding: 20px 0;
          font-family: Visuelt-Medium;
          display: block;
          text-decoration: none;
          border-radius: 5px;
          font-size: 18px;
          color: #FFFFFF;
          text-align: center;

          &:hover {
            background: #ce5b2a;
          }
        }

        .donioBot {
          text-align: center;
          
          p {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #000000;
            text-align: center;
            text-align: center;
            margin: 20px auto;
            line-height: 25px;
            margin-bottom: 10px;
          }

          img {
            margin: 0 auto;
          }
        }
      }

      .funeralInfo {
        .boxInner {
          p {
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #000000;
            line-height: 25px;
          }

          .adresa {
            p {
              margin-top: 20px;
              margin-bottom: 20px;

              span {
                font-family: Visuelt-Bold;
              }
            }

            iframe {
              width: 100%;
              height: 150px;
              border-radius: 5px;
              margin-bottom: 20px;
            }
          }
        }
      }

      .shareBox {
        .boxInner {
          .label {
            font-family: Visuelt-Bold;
            text-transform: uppercase;
            font-size: 14px;
            color: #000000;
            letter-spacing: 0;
            margin-bottom: 15px;
          }

          .copyInput {
            margin-bottom: 25px;
            position: relative;
            border-radius: 5px;

            div {
              background-image: linear-gradient(90deg, rgba(242,242,242,0.00) 0%, #F2F2F2 15%);
              width: 130px;
              height: 100%;
              border-radius: 5px;
              position: absolute;
              top: 0;
              right: 0;

              button {
                background: #535353;
                border-radius: 5px;
                font-family: Visuelt-Medium;
                font-size: 14px;
                color: #FFFFFF;
                border: 0;
                text-align: center;
                margin-left: auto;
                display: block;
                position: absolute;
                top: 10px;
                right: 15px;
                padding: 6px 10px;

                &:hover {
                  cursor: pointer;
                  background: #2f2f2f;
                }

                &:active {
                  cursor: pointer;
                  background: black;
                }
              }
            }

            input {
              background: #F2F2F2;
              border: 0;
              width: calc(100% - 30px - 80px);
              padding: 15px;
              padding-right: 95px;
              border-radius: 5px;
              font-family: Visuelt-Regular;
              font-size: 16px;
              color: #000000;
            }
          }

          .socials {
            button {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border: 0;
              border-radius: 5px;
              padding: 15px;
              width: calc(100%);
              text-align: left;
              margin-bottom: 15px;
              height: 50px;

              &:last-child {
                margin-bottom: 0;
              }

              div {
                display: flex;
                align-items: center;

                span {
                  font-family: Visuelt-Medium;
                  font-size: 16px;
                  color: #FFFFFF;
                  margin-bottom: -2px;
                  margin-left: 15px;
                  position: absolute;
                  left: 65px;
                }
              }
              
            }

            .facebook {
              background: #007afa;

              &:hover {
                cursor: pointer;
                background: #006ada;
              }
            }

            .twitter {
              background: #00a4f9;

              &:hover {
                cursor: pointer;
                background: #0096e4;
              }
            }

            .email {
              background: rgb(255,238,98);

              &:hover {
                cursor: pointer;
                background: #EFD83E;
              }

              span {
                color: black;
              }
            }
          }
        }
      }

      .upravitParte {
        button {
          font-family: Visuelt-Medium;
          font-size: 16px;
          color: #000000;
          border: 0;
          background: none;
          text-decoration: underline;
        
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
`;



const ObrazekGalerie = ( {fotka, removeGalleryImage }) => {
  const [isHidden, setIsHidden] = useState(false);

  if (!fotka.dummy) {

    const remove = () => {
      setIsHidden(true);
      removeGalleryImage(fotka.id);
    }

    return (
      <>{(!isHidden) &&
        <ObrazekGalerieInner imageUrl={(fotka.src) ? fotka.src : fotka.url}>
          <div className="innerPic" />
          <button onClick={() => remove()}>
            <img src={Xko} alt="" />
          </button>
        </ObrazekGalerieInner>
      }</>
    );
  }

  else {
    return (
      <ObrazekGalerieInner imageUrl={Loading}>
        <div className="innerPic loadingInnerPic" />
      </ObrazekGalerieInner>
    );
  }

  
}

function datesToAge(deathDateO, birthDateO) {
  var deathDate = Date.parse(deathDateO);
  var birthDate = Date.parse(birthDateO);
  return (Math.round((deathDate - birthDate)/1000/60/60/24/365));
}

function formatedDate(dateO) {
  var date = Date.parse(dateO);
  date = new Date(date);
  return (date.toLocaleDateString("cs-CZ", {dateStyle: "long"}));
}



const Upravit = ({ data, slug }) => {
  let backendUrl = "";

  if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:1337'
  }

  if (process.env.NODE_ENV === 'production') {
    backendUrl = 'https://goodbye-backend.herokuapp.com'
  }


  const isMin1000 = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  const [galleryItems, setGalleryItems] = useState([]);
  const [galleryItemsReal, setGalleryItemsReal] = useState([]);
  const [donioData, setDonioData] = useState();
  const [parteData, setParteData] = useState();
  const parteDataRef = useRef();
  parteDataRef.current = parteData;
  const [descText, setDescText] = useState("");
  const [jwtState, setJwt] = useState("");
  const jwtRef = useRef();
  jwtRef.current = jwtState;
  const [isPicLoading, setIsPicLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [donioFullData, setDonioFullData] = useState();

  const [cookies, setCookie, removeCookie] = useCookies(['session_id']);
  const initParteId = cookies.session_id;

  const [isUpravitInfoModal, setUpravitInfoModal] = useState(false);
  const [isOsobniInfoModal, setOsobniInfoModal] = useState(false);
  const [isPohrebInfoModal, setPohrebInfoModal] = useState(false);
  const [isDonioInfoModal, setDonioInfoModal] = useState(false);
  const [isVymazatParteModal, setVymazatParteModal] = useState(false);

  


  const onDrop = useCallback(async (acceptedFiles, jwt) => {

    // Do something with the files

    setIsPicLoading(true);

    if (acceptedFiles[0]) {
      const formData = new FormData();
      formData.append("files", acceptedFiles[0]);

      await uploadSoubor(formData, jwt);
    }

    else {
      setIsPicLoading(false);
    }

    

  }, []);

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ maxFiles: 1, accept: 'image/jpeg, image/png', onDrop });


  const savePublish = async () => {

    if (descText) {

      if (parteData.profilePicture) {
        const res = await axios.put(`${backendUrl}/partes/${parteData._id}`, {
          "description": descText,
          "isPublished": true,
        }, {
          headers: {
            Authorization: `Bearer ${jwtState}`
          },
        });
  
        navigate(`/prt/${parteData.slug}/sdilet`);
      }

      else {
        displayError("Přidejte prosím fotku vašeho blízkého");
      }
    }

    else {
      displayError("Vyplňte prosím text o zesnulém");
    }
  }

  const getNewData = async (jwt, isFirst) => {

    
    const res = await axios.get(`${backendUrl}/partes/id/${initParteId}`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      },
    });

    setParteData(res.data);


    if (res.data.donioId !== null && res.data.donioId !== 0) {
      axios.get(`${backendUrl}/donios/${res.data.donioId}`).then((res) => {
        setDonioData(res.data);        
      });
    }








    if (isFirst) {
      setDescText(res.data.description);
    }

    let items = [];


    res.data.gallery.forEach(element => {

      let fotka = element.url;

      if (typeof element.formats !== "undefined") {
        fotka = element.formats.thumbnail.url;
      }

      let item = {
        src: element.url,
        thumbnail: fotka,
        w: element.width,
        h: element.height,
        title: 'Obrázek',
        id: element._id
      };

      items.push(item)
    });

    setGalleryItems(items);
    setGalleryItemsReal(res.data.gallery);

  }

  const uploadData = async (jwt, inputData) => {


    const res = await axios.put(`${backendUrl}/partes/${parteDataRef.current._id}`, inputData, {
      headers: {
        Authorization: `Bearer ${jwt}`
      },
    });


    await getNewData(jwt, false);


    return(res);
  }

  const handleTextarea = (textik) => {
    if (textik.length <= 2000) {
      setDescText(textik);
    }

    else {
      displayError("Text nesmí překročit 2000 znaků");
    }
  }

  const displayError = (errorText) => {
    setErrorMessage(errorText);

    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  }

  const removePicture = async () => {
    setIsPicLoading(true);

    await uploadData(jwtState, {
      "profilePicture": null
    });

    setIsPicLoading(false);
  }

  const uploadSoubor = async (file) => {


    axios.post(`${backendUrl}/upload`, file, {
      headers: {
        Authorization: `Bearer ${jwtRef.current}`
      },
    }).then(r => {


      uploadData(jwtRef.current, {
        "profilePicture": r.data[0]
      }).then(res => {
        setIsPicLoading(false);
      });  
    });
  }

  const removeGalleryImage = async (id) => {

    // smazat file

    axios.delete(`${backendUrl}/upload/files/${id}`, {
      headers: {
        Authorization: `Bearer ${jwtRef.current}`
      },
    }).then(r => {
      getNewData(jwtRef.current, false);
    });

    // upravit PUT data
  }

  const removeDonio = () => {
    axios.put(`${backendUrl}/partes/${parteDataRef.current._id}`, {
      donioId: 0
    }, {
      headers: {
        Authorization: `Bearer ${jwtRef.current}`
      },
    }).then((r) => {

      getNewData(jwtRef.current, false);
    });
  }



  useEffect(() => {
    
    if (!cookies.session_id || cookies.session_id === "") {
      if (typeof window !== 'undefined') {
        window.location = '/vytvorit-parte'
      }
    }

    else {
      // napojujeme se na API
      axios.post(`${backendUrl}/auth/local`, {
        identifier: 'app@goodbye.cz',
        password: 'Good.Bye123',
      }).then((res) => {
        setJwt(res.data.jwt);
        getNewData(res.data.jwt, true);


        axios.get(`${backendUrl}/donios`, {
          headers: {
            Authorization: `Bearer ${res.data.jwt}`
          },
        }).then(r => {
          setDonioFullData(r.data);
        });
      });
    }    
  }, []);

  let pocitadlo = 0;


  
  if (parteData) {
    return (
      <StaticQuery
        query = {graphql`
          query {
            donioSample: file(relativePath: { eq: "donioSample.png" }) {
              childImageSharp {
                fluid(maxWidth: 320) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={data => (<>
          {isUpravitInfoModal && (
            <ParteModal heading="Upravit informace o zesnulém" setIsVisible={setUpravitInfoModal}>
              <UpravitInfoModal 
                jwt={jwtState}
                uploadData={uploadData}
                parteData={parteData} 
                displayError={displayError}
                setIsVisible={setUpravitInfoModal}
              />
            </ParteModal>
          )}

          {isOsobniInfoModal && (
            <ParteModal heading="Upravit osobní údaje" setIsVisible={setOsobniInfoModal}>
              <OsobniInfoModal 
                jwt={jwtState}
                uploadData={uploadData}
                parteData={parteData} 
                displayError={displayError}
                setIsVisible={setOsobniInfoModal}
              />
            </ParteModal>
          )}

          {isPohrebInfoModal && (
            <ParteModal heading="Informace o pohřbu" setIsVisible={setPohrebInfoModal}>
              <PohrebInfoModal 
                jwt={jwtState}
                uploadData={uploadData}
                parteData={parteData} 
                displayError={displayError}
                setIsVisible={setPohrebInfoModal}
              />
            </ParteModal>
          )}

          {isDonioInfoModal && (
            <ParteModal isWide heading="Upravit dobročinnou sbírku" setIsVisible={setDonioInfoModal}>
              <DonioInfoModal
                parteData={parteData}
                jwt={jwtState}
                getNewData={getNewData}
                parteId={initParteId}
                setDonioInfoModal={setDonioInfoModal}
                donioFullData={donioFullData}
                setDonioData={setDonioData}
              />
            </ParteModal>
          )}

          {isVymazatParteModal && (
            <ParteModal heading="Vymazat parte" setIsVisible={setVymazatParteModal}>
              <VymazatParteModal
                jwt={jwtState}
                setIsVisible={setVymazatParteModal}
                parteData={parteData}
              />
            </ParteModal>
          )}


          <UpravitInner>    
            <section className="obsahWrapper">
              <div className="obsahInner">
                <div className="basicInfo">
                  {isMin1000 &&
                    <button onClick={() => savePublish()} className="saveButton">{(parteData.isPublished) ? "Uložit" : "Uložit a publikovat"}</button>
                  }

                  {(errorMessage) && <p className="errorMessage">{errorMessage}</p>}

                  <div className="profile">

                    {(isPicLoading) ? 
                      <div className="obrazek">
                        <VnitniObrazek className="loadingPic" imageUrl={Loading} />
                      </div>
                    :<>
                      {(parteData.profilePicture) ?
                        <>
                          <div className="obrazek">
                            <VnitniObrazek imageUrl={parteData.profilePicture.url} />
                            <button className="removePic" onClick={() => removePicture()}>
                              <img src={Xko} alt="" />
                            </button>
                          </div>
                        </>
                      :
                        <div className="obrazek obrazekReadyTodo">
                          <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <div className="obrazekInner">
                              <img src={ImageIcon} alt="" />
                              <p>Přetáhnout nebo <span>vybrat fotku</span></p>
                            </div>
                          </div>
                        </div>
                      }
                    </>}
                      
                    <div className="text">
                      <div>
                        <h1>{parteData.name}</h1>
                        <span>{datesToAge(parteData.deathDate, parteData.birthDate)} let</span>
                      </div>
                      <p>{formatedDate(parteData.birthDate)} - {formatedDate(parteData.deathDate)}</p>
                      <button onClick={() => setUpravitInfoModal(true)} className="upravitButton">Upravit</button>
                    </div>
                  </div>
                
                  <div className="textSection">
                    <span>{(descText) ? descText.length : 0} / 2000 zbývajících znaků</span>
                    <textarea value={descText} onChange={e => handleTextarea(e.target.value)} placeholder="Napište pár slov o životě vašeho blízkého a sdílejte společné vzpomínky…"/>
                  </div>

                </div>

                <div className="grayZone">
                  <div className="grayZoneInner">
                    <div className="galerie">
                      <h2>Galerie</h2>
                
                      <PretahnoutFotky jwt={jwtState} galerieItems={galleryItemsReal} uploadData={uploadData} setGalleryItems={setGalleryItems} />

                      <div className="galerieInner">
                        {(galleryItems.length > 0) &&
                          <div className="galerieInnerWrap">

                            {galleryItems.map((fotka) => {
                              pocitadlo++;

                              return (
                                <ObrazekGalerie fotka={fotka} key={pocitadlo} removeGalleryImage={removeGalleryImage} />
                              );                      
                            })}

                          </div>
                        }
                      </div>
                    </div>
                    <div className="funeralInfo">
                      <h2>Upravit osobní údaje</h2>
                      <div className="text">
                        <div className="udaj">
                          <span>Vaše jméno</span>
                          <div>{parteData.creatorName}</div>
                        </div>
                        <div className="udaj">
                          <span>E-mail</span>
                          <div>{parteData.email}</div>
                        </div>
                      </div>
                      <button onClick={() => setOsobniInfoModal(true)} className="upravitButton">Upravit</button>
                      <button onClick={() => setVymazatParteModal(true)} className="vymazatParte">Vymazat parte</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rightPanel">
                {!isMin1000 &&
                  <button onClick={() => savePublish()} className="saveButton">{(parteData.isPublished) ? "Uložit" : "Uložit a publikovat"}</button>
                }








                <div className="box donioBox">

                  {(parteData.donioId) ?
                    <h2>Přispějte na tuto sbírku jako památku na vašeho blízkého</h2>
                  : 
                    <h2>Přidejte dobročinnou sbírku</h2>
                  }

                  <div className="boxInner">
                    
                    {(parteData.donioId) ?
                      <>
                        {(donioData) ?
                        <>
                          <div className="donioPic">
                            <img src={donioData.imageUrl} alt={donioData.data.name} />
                          </div>
                          <h3>{donioData.data.name}</h3>
                          <ProgressBar completed={(donioData.maxAmount) ? ((donioData.data.currentAmount / donioData.maxAmount) * 100) : 100} />
                          <div className="vybrano">Vybráno {parseFloat(donioData.data.currentAmount).toLocaleString("cs")} Kč</div>
                          <div className="odDarcu">Od {parseFloat(donioData.data.donationsCount).toLocaleString("cs")} dárců</div>
                          <button onClick={() => removeDonio()} className="upravitButton">Odebrat</button>
                        </>
                        :
                          <img className="loadingDonio" src={Loading} alt="Načítání..." />
                        }
                        
                        
                      </>
                    : 
                      <button onClick={() => setDonioInfoModal(true)} className="upravitButton">Přidat</button>
                    }
                  </div>
                </div>






                
                <div className="box funeralInfo">
                  <h2>Přidat informace o pohřbu</h2>
                  <div className="boxInner">

                    {(parteData.funeralDescription) ?
                      <>
                        <p>{parteData.funeralDescription}</p>

                        {(parteData.funeralAddress) &&
                          <div className="adresa">
                            <p><span>Adresa:</span> {parteData.funeralAddress}</p>
                            <iframe
                              frameBorder="0"
                              title={parteData.funeralAddress}
                              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDR-wPIwbrQ2srKQ5FYSRXXCxdbPgAFhS4&q=${parteData.funeralAddress}`}>
                            </iframe>
                          </div>
                        }

                        <button onClick={() => setPohrebInfoModal(true)} className="upravitButton">Upravit</button>
                      </>
                    :
                      <button onClick={() => setPohrebInfoModal(true)} className="upravitButton">Přidat</button>
                    }
                    
                  </div>
                </div>
                {isMin1000 &&
                  <button onClick={() => savePublish()} className="saveButton">{(parteData.isPublished) ? "Uložit" : "Uložit a publikovat"}</button>
                }
              </div>
            </section>
          </UpravitInner>
        </>)}
      />
    );
  }

  else {
    return (
      <UpravitInner>
        <div className="loadingScreen">
          <img src={Loading} alt="Načítání..." />
        </div>
      </UpravitInner>
    );
  }
}


export default Upravit;
