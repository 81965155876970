import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import ProgressBar from "../../components/parte/ProgressBar";
import axios from "axios";
import { useMediaQuery } from 'react-responsive';

import Next from "../../assets/img/next.svg";
import Prev from "../../assets/img/prev.svg";



import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import cs from "date-fns/locale/cs"; // the locale you want
registerLocale("cs", cs); // register it with the name you want
SwiperCore.use([Navigation]);



const UpravitInner = styled.section`

  .wrapper {
    margin-top: 10px;
    margin-bottom: 10px;

    @media (max-width: 580px) {
      margin: 0;
    }

    h2 {
      width: calc(100% - 100px);
      margin: 0 auto;
      font-family: Visuelt-Medium;
      font-size: 22px;
      color: #000000;
      margin-bottom: 35px;

      @media (max-width: 580px) {
        width: calc(100% - 50px);
      }
    }

    .swiper-container {
      position: relative;

      

      .swiper-button-prev {
        background: url(${Prev});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        height: 70px;
        position: absolute;
        left: 0;
        width: 70px;
        bottom: 0;

        @media (max-width: 580px) {
          display: none;
        }

        &::after {
          content: none;
        }
      }

      .swiper-button-next {
        background: url(${Next});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        height: 70px;
        width: 70px;
        position: absolute;
        right: 0;
        bottom: 0;

        @media (max-width: 580px) {
          display: none;
        }

        &::after {
          content: none;
        }
      }
    }
  }

  .swiper-slide {
    max-width: 300px;
    width: calc(100% - 100px);

    @media (max-width: 580px) {
      width: calc(100% - 80px);
    }
  }

  .swiper-wrapper {
    margin-left: 50px;

    @media (max-width: 580px) {
      margin-left: 40px;
    }
  }

  .bublina {
    background: #FFFFFF;
    border: 1px solid #0000002b;
    box-shadow: 0 3px 14px 0 rgba(0,0,0,0.09);
    border-radius: 5px;
    overflow: hidden;
    height: 400px;

    a {
      text-decoration: none;
    }

    h3 {
      font-family: NY-Semibold;
      font-size: 16px;
      color: #000000;
      width: calc(100% - 40px);
      margin: 0 auto;
      margin-top: 15px;
    }

    .bot {
      width: calc(100% - 40px);
      position: absolute;
      bottom: 20px;
      left: 20px;

      .procenta {
        position: absolute;
        top: 20px;
        right: 0;
        color: #e66d45;
        font-family: Visuelt-Bold;
      }

      .nabrano {
        color: #6ac362;
      }

      p {
        font-family: Visuelt-Medium;

        &:first-child {
          font-size: 16px;
          color: #000000;
          margin-top: 12px;
        }

        &:last-child {
          
        }
      }

      .lilPopisek {
        font-size: 14px;
        color: #9E9E9E;
        margin-top: 5px;
      }

      .vybratButton {
        background: white;
        width: 100%;
        padding: 10px 0;
        font-family: Visuelt-Medium;
        display: block;
        -webkit-text-decoration: none;
        text-decoration: none;
        border-radius: 5px;
        font-size: 16px;
        color: #e66e3b;
        text-align: center;
        border: 2px solid #e66e3b;
        cursor: pointer;
        margin-top: 20px;

        &:hover {
          background: #fff5f0;
        }
      }

      .selected {
        background: #e66e3b;
        color: #fff;

        &:hover {
          background: #ce5d2d;
        }
      }
    }
  }

  .spodek {
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    align-items: center;
    width: calc(100% - 100px);

    @media (max-width: 580px) {
      width: calc(100% - 50px);
      display: block;
    }

    button {
      background: rgb(230, 110, 59);
      border-radius: 5px;
      border: 0;
      padding: 11px 37px;
      font-family: Visuelt-Medium;
      font-size: 16px;
      color: #FFFFFF;
      margin-right: 30px;
      cursor: pointer;

      &:hover {
        background: #ce5b2a;
      }

      @media (max-width: 580px) {
        display: block;
      }
    }

    p {
      font-family: Visuelt-Regular;
      font-size: 16px;
      color: #000000;

      @media (max-width: 580px) {
        margin-top: 20px;
        display: block;
      }

      a {
        font-family: Visuelt-Regular;
        color: #D7744F;
      }
    }
  }
`;


const DonioBublina = ({ o, selectProject, unselectProject, selectedProject}) => {


  let progress = 100;

  if (o.maxAmount) {
    progress = (o.data.currentAmount / o.maxAmount) * 100;
  }


  return (

    <div className="bublina">
      <a target="_blank" href={o.url}>
        <img src={o.imageUrl} alt={o.title} />
        <h3>{o.data.name}</h3>
      </a>

      <div className="bot">
        <ProgressBar height={8} completed={progress} />
        <div className="vybrano">
          <p>Vybráno {parseFloat(o.data.currentAmount).toLocaleString("cs")} Kč</p>

          {(o.maxAmount) ? 
            <>
              <p className="lilPopisek">z {parseFloat(o.maxAmount).toLocaleString("cs")} Kč</p>
              <span className={`procenta ${(progress >= 100) && "nabrano"}`}>{Math.round(progress)}%</span>
            </>
          :
            <p className="lilPopisek">od {parseFloat(o.data.donationsCount).toLocaleString("cs")} dárců</p>
          }
        </div>

        {(selectedProject === o.donioId)
        ?
          <button onClick={() => unselectProject()} className="vybratButton selected">Vybráno</button>

        :
          <button onClick={() => selectProject(o.donioId)} className="vybratButton">Vybrat</button>
        }

      </div>
    </div>


  );
}



const DonioInfoModal = ({ getNewData, jwt, parteId, setDonioInfoModal, donioFullData, setDonioData }) => {
  const [selectedProject, setSelectedProject] = useState();

  let backendUrl = "";

  if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:1337'
  }

  if (process.env.NODE_ENV === 'production') {
    backendUrl = 'https://goodbye-backend.herokuapp.com'
  }

  const selectProject = (donioId) => {
    axios.put(`${backendUrl}/partes/${parteId}`, {
      donioId: donioId
    }, {
      headers: {
        Authorization: `Bearer ${jwt}`
      },
    });

    setSelectedProject(donioId);
    // scroll až dolů
  }

  const unselectProject = () => {
    axios.put(`${backendUrl}/partes/${parteId}`, {
      donioId: 0
    }, {
      headers: {
        Authorization: `Bearer ${jwt}`
      },
    });

    setSelectedProject(0);
  }

  const goBack = () => {
    getNewData(jwt, false);
    setDonioData();
    setDonioInfoModal(false);
  }
  
  useEffect(() => {
    
  }, []);

  const isLess580 = useMediaQuery({
    query: '(max-width: 580px)'
  });
    

  let key = 0;

  return (
    <UpravitInner>
      <div className="wrapper">
        <h2>Vyberte si jeden z projektů</h2>

        {(donioFullData) ? 
          <Swiper slidesPerView={"auto"} loop={true} spaceBetween={(isLess580) ? 15 : 30} navigation={true} observer observeParents>
            {donioFullData.map((o) => {
              key++; 

              if (o.isFeatured) {
                return (
                  <SwiperSlide key={key}>
                    <DonioBublina
                      o={o}
                      selectProject={selectProject}
                      unselectProject={unselectProject}
                      selectedProject={selectedProject}
                    />
                  </SwiperSlide>
                )
              }
            })}
          </Swiper>
        :
          <h1>Načítání...</h1>
        }

        <div className="spodek">
          <button onClick={() => goBack()}>Pokračovat</button>
          <p>Chcete založit vlastní sbírku? <a target="_blank" href="https://www.donio.cz/navrh">Zjistit více</a></p>
        </div>

      </div>
    </UpravitInner>
  )
}


export default DonioInfoModal;
