import React, { useState, useRef } from "react";
import 'swiper/swiper-bundle.min.css';
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Sha1 from "../../components/hash.js";


import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import cs from "date-fns/locale/cs"; // the locale you want
registerLocale("cs", cs); // register it with the name you want



const UpravitInner = styled.section`

`;



const UpravitInfoModal = ({ parteData, uploadData, jwt, displayError, setIsVisible }) => {

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors, watch, control } = useForm();
  const onSubmit = data => handleForm(data);
  const pass1 = useRef({});
  pass1.current = watch("pass1", "");

  const handleForm = async (data) => {
    setIsLoading(true);

    let dataToUpload = {
      "creatorName": data.creatorName,
      "email": data.email,
    };

    if (data.pass2) {
      dataToUpload = {
        "creatorName": data.creatorName,
        "email": data.email,
        "password": Sha1.hash(data.pass2, {}),
      };
    }

    


    await uploadData(jwt, dataToUpload);

    setIsVisible(false);
    setIsLoading(false);

    // přidat posílání confirmačního mailu
  }


  return (
    <UpravitInner>
      <form onSubmit={handleSubmit(onSubmit)}>

        <label className={`pole ${errors.creatorName && "inputError"}`}>
          <span>Vaše jméno</span>
          
          <input 
            ref={register({ required: true })}
            type="text"
            name="creatorName"
            defaultValue={(parteData.creatorName) ? parteData.creatorName : null}
          />

          {errors.creatorName && <div className="errorMsg">Toto pole je povinné</div>}
        </label>

        <label className={`pole ${errors.email && "inputError"}`}>
          <span>Váš e-mail</span>
          
          <input 
            ref={register({ required: true })}
            type="text"
            name="email"
            defaultValue={(parteData.email) ? parteData.email : null}
          />

          {errors.email && <div className="errorMsg">Toto pole je povinné</div>}
        </label>

        <label className={`pole ${errors.pass1 && "inputError"}`}>
          <span>Nové heslo</span>
          
          <input 
            ref={register()}
            type="password"
            name="pass1"
          />

          {errors.pass1 && <div className="errorMsg">Toto pole je povinné</div>}
        </label>

        <label className={`pole ${errors.pass2 && "inputError"}`}>
          <span>Zoopakovat heslo</span>

          <input 
            ref={register({
              validate: value => value === pass1.current || "Hesla se neshodují"
            })}
            type="password"
            name="pass2"
          />

          {errors.pass2 && <div className="errorMsg">Hesla se neshodují</div>}
        </label>

        <div className="buttons">
          <input className="submit" type="submit" value={(isLoading) ? "Načítání..." : "Uložit"} />
        </div>
      </form>
    </UpravitInner>
  )
}


export default UpravitInfoModal;
