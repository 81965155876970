import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { useCookies } from "react-cookie";
import { Link } from "gatsby";
import ProgressBar from "../../components/parte/ProgressBar";
import axios from "axios";
import { animateScroll } from "react-scroll";


import DonioBackground from "../../assets/img/donioBackground.svg";
import DonioLogo from "../../assets/img/donioLogoB.svg";
import Next from "../../assets/img/next.svg";
import Prev from "../../assets/img/prev.svg";
import Loading from "../../assets/img/loadingTransparent.svg";

SwiperCore.use([Navigation]);


const DonioBublina = ({ o, selectProject, unselectProject, selectedProject}) => {

  let progress = 100;

  if (o.maxAmount) {
    progress = (o.data.currentAmount / o.maxAmount) * 100;
  }


  return (

    <div className="bublina">
      <a target="_blank" href={o.url}>
        <img src={o.imageUrl} alt={o.title} />
        <h3>{o.data.name}</h3>
      </a>

      <div className="bot">
        <ProgressBar height={8} completed={progress} />
        <div className="vybrano">
          <p>Vybráno {parseFloat(o.data.currentAmount).toLocaleString("cs")} Kč</p>

          {(o.maxAmount) ? 
            <>
              <p className="lilPopisek">z {parseFloat(o.maxAmount).toLocaleString("cs")} Kč</p>
              <span className={`procenta ${(progress >= 100) && "nabrano"}`}>{Math.round(progress)}%</span>
            </>
          :
            <p className="lilPopisek">od {parseFloat(o.data.donationsCount).toLocaleString("cs")} dárců</p>
          }
        </div>

        {(selectedProject === o.donioId)
        ?
          <button onClick={() => unselectProject()} className="vybratButton selected">Vybráno</button>

        :
          <button onClick={() => selectProject(o.donioId)} className="vybratButton">Vybrat</button>
        }

      </div>
    </div>


  );
}









const DonioInner = styled.section`
  overflow: hidden;
  display: flex;

  .right {
    background: url(${props => props.donioBackground});
    width: 40%;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1200px) {
      width: calc(100% - 700px);
    }

    @media (max-width: 740px) {
      display: none;
    }
  }

  .left {
    width: 60%;

    @media (max-width: 1200px) {
      min-width: 0;
      width: calc(700px);
    }

    @media (max-width: 740px) {
      width: 100%;
    }

    .loadingScreen {
      height: calc(100vh - 65px);
      position: relative;
      
      img {
        margin: 0 auto;
        padding-top: 200px;
        width: 200px;
        display: block;
      }
    }

    .leftInner {
      max-width: 500px;
      margin-left: calc((100vw - 1150px)/2 + 15px);
      
      @media (max-width: 1200px) {
        margin-left: 50px;
        max-width: none;
        width: calc(600px);
      }

      @media (max-width: 740px) {
        width: calc(100% - 50px);
        margin: 0 auto;
      }

      .donioLogo {
        margin-bottom: 25px;
        margin-top: 60px;

        @media (max-width: 740px) {
          margin-top: 35px;
        }
      }

      h1 {
        font-family: NY-Medium;
        font-size: 32px;
        color: #000000;
        line-height: 44px;

        @media (max-width: 740px) {
          font-size: 27px;
          margin-bottom: 20px;
          line-height: 40px;
        }
      }

      .desc {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #474747;
        line-height: 25px;
        margin-top: 35px;

        a {
          color: #474747;
          font-family: Visuelt-Regular;
        }
      }

      h2 {
        font-family: Visuelt-Medium;
        font-size: 22px;
        color: #000000;
        margin-bottom: 35px;

        @media (max-width: 740px) {
          margin-bottom: 20px;
          font-size: 20px;
        }
      }

      .tipyNaProjekty {
        position: relative;
        margin-top: 80px;
        height: 590px;
        margin-bottom: 80px;

        @media (max-width: 740px) {
          margin-top: 60px;
          margin-bottom: 40px;
          height: auto;
        }

        .swiper-wrapper {
          position: relative;
        }

        .swiper-container {
          overflow: unset !important;
          margin: 0;
          padding: 30px 0;

          .swiper-slide {
            width: 270px;
          }
        
          .bublina {
            background: #FFFFFF;
            border: 1px solid #0000002b;
            box-shadow: 0 3px 14px 0 rgba(0,0,0,0.09);
            border-radius: 5px;
            overflow: hidden;
            height: 400px;

            a {
              text-decoration: none;
            }

            h3 {
              font-family: NY-Semibold;
              font-size: 16px;
              color: #000000;
              width: calc(100% - 40px);
              margin: 0 auto;
              margin-top: 15px;
            }

            .bot {
              width: calc(100% - 40px);
              position: absolute;
              bottom: 20px;
              left: 20px;

              .procenta {
                position: absolute;
                top: 20px;
                right: 0;
                color: #e66d45;
                font-family: Visuelt-Bold;
              }

              .nabrano {
                color: #6ac362;
              }

              p {
                font-family: Visuelt-Medium;

                &:first-child {
                  font-size: 16px;
                  color: #000000;
                  margin-top: 12px;
                }

                &:last-child {
                  
                }
              }

              .lilPopisek {
                font-size: 14px;
                color: #9E9E9E;
                margin-top: 5px;
              }

              .vybratButton {
                background: white;
                width: 100%;
                padding: 10px 0;
                font-family: Visuelt-Medium;
                display: block;
                -webkit-text-decoration: none;
                text-decoration: none;
                border-radius: 5px;
                font-size: 16px;
                color: #e66e3b;
                text-align: center;
                border: 2px solid #e66e3b;
                cursor: pointer;
                margin-top: 20px;

                &:hover {
                  background: #fff5f0;
                }
              }

              .selected {
                background: #e66e3b;
                color: #fff;

                &:hover {
                  background: #ce5d2d;
                }
              }
            }
          }
        }

        .swiper-button-prev {
          left: 0;
          background: url(${Prev});
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center;
          height: 70px;
          top: 480px;
          width: 70px;
          position: absolute;

          @media (max-width: 740px) {
            display: none;
          }

          &::after {
            content: none;
          }
        }

        .swiper-button-next {
          left: calc(100vh - 70px - (100vh - 1150px));
          background: url(${Next});
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center;
          height: 70px;
          top: 480px;
          width: 70px;
          position: absolute;

          @media (max-width: 1200px) {
            left: calc(100vw - 70px - 100px);
          }

          @media (max-width: 740px) {
            left: calc(100vw - 70px - 50px);
            display: none;

          }

          @media (max-width: 400px) {
          }

          &::after {
            content: none;
          }
        }
      }

      .vyhledavani {
        max-width: 500px;

        p {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #474747;
          line-height: 25px;
        }

        .zalozitVlastni {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #D7744F;
          margin-top: 20px;

          a {
            color: #D7744F;
          }
        }
      }

      .preskocit {
        margin-top: 80px;
        padding-bottom: 90px;

        @media (max-width: 740px) {
          margin-top: 60px;
        }

        a {
          background: #566FEF;
          border-radius: 5px;
          padding: 14px 0;
          width: calc(100% - 50px);
          max-width: 290px;
          margin: 0 auto;
          margin-left: 0;
          display: block;
          -webkit-text-decoration: none;
          text-decoration: none;
          font-family: Visuelt-Medium;
          font-size: 18px;
          color: #FFFFFF;
          text-align: center;
          margin-top: 40px;

          &:hover {
            background: #4a60d0;
          }
        }
      }

      .pokracovat {
        margin-top: 80px;
        padding-bottom: 90px;

        @media (max-width: 740px) {
          margin-top: 60px;
        }

        a {
          background: #e66e3b;
          border-radius: 5px;
          padding: 14px 0;
          width: calc(100% - 50px);
          max-width: 290px;
          margin: 0 auto;
          margin-left: 0;
          display: block;
          -webkit-text-decoration: none;
          text-decoration: none;
          font-family: Visuelt-Medium;
          font-size: 18px;
          color: #FFFFFF;
          text-align: center;
          margin-top: 40px;

          &:hover {
            background: #ce5d2d;
          }
        }
      }

  


    }
  }

  
`;








const Donio = ({ data }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['session_id']);
  const [donioData, setDonioData] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const initParteId = cookies.session_id;
  const [jwtState, setJwt] = useState("");

  let backendUrl = "";

  if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:1337'
  }

  if (process.env.NODE_ENV === 'production') {
    backendUrl = 'https://goodbye-backend.herokuapp.com'
  }





  useEffect(() => {
    if (!cookies.session_id || cookies.session_id === "") {
      if (typeof window !== 'undefined') {
        window.location = '/vytvorit-parte'
      }
    }

    else {
      axios.post(`${backendUrl}/auth/local`, {
        identifier: 'app@goodbye.cz',
        password: 'Good.Bye123',
      }).then((res) => {
        setJwt(res.data.jwt);

        axios.get(`${backendUrl}/partes/id/${initParteId}`, {
          headers: {
            Authorization: `Bearer ${res.data.jwt}`
          },
        }).then(r => {
          setSelectedProject(r.data.donioId);
        });
      });
    }

    axios.get(`${backendUrl}/donios`, {
    }).then((r) => {
      setDonioData(r.data);

      axios.put(`${backendUrl}/partes/${initParteId}`, {
        donioId: 0
      }, {
        headers: {
          Authorization: `Bearer ${jwtState}`
        },
      });
    });
  }, []);

  



  const selectProject = (donioId) => {

    axios.put(`${backendUrl}/partes/${initParteId}`, {
      donioId: donioId
    }, {
      headers: {
        Authorization: `Bearer ${jwtState}`
      },
    });

    setSelectedProject(donioId);

    animateScroll.scrollToBottom({duration: 500});

    // scroll až dolů
  }

  const unselectProject = () => {

    axios.put(`${backendUrl}/partes/${initParteId}`, {
      donioId: 0
    }, {
      headers: {
        Authorization: `Bearer ${jwtState}`
      },
    });

    setSelectedProject(0);

  }



  let key = 0;

  return (
    <DonioInner donioBackground={DonioBackground} >
      <div className="left">
        {(donioData) ?
          <div className="leftInner">
            <img alt="Donio" className="donioLogo" src={DonioLogo} />
            <h1>Chtěli byste skrze parte vybrat peníze na dobročinné účely?</h1>
            <p className="desc">Ve spolupráci s projektem <a target="_blank" href="https://donio.cz/">Donio</a> můžete ke každému online parte připojit libovolný dobročinný projekt. Na ten mohou potom pozůstalí, kteří parte uvidí, během pár kliků přispět. Vždy předáme 100% daru.<br/><br/>Může to být například téma, o které se váš blízký zajímal. Nebo můžete <a target="_blank" href="https://www.donio.cz/navrh">založit i vlastní sbírku</a> pro rodinu (např. na pokrytí nákladů za pohřeb).</p>

            <div className="tipyNaProjekty">
              <h2>Naše tipy na projekty</h2>
              <Swiper setWrapperSize={true} slidesPerView={"auto"} loop={true} spaceBetween={40} navigation={true} observer observeParents>
                {donioData.map((o) => {
                  key++; 

                  if (o.isFeatured) {
                    return (
                      <SwiperSlide key={key}>
                        <DonioBublina
                          o={o}
                          selectProject={selectProject}
                          unselectProject={unselectProject}
                          selectedProject={selectedProject}
                        />
                      </SwiperSlide>
                    )
                  }
                })}
              </Swiper>
            </div>

            <div className="vyhledavani">
              <h2>Založit vlastní sbírku</h2>
              <p>K založení sbírky stačí vyplnit formulář, ve kterém uvedete důvody založení sbírky, jak plánujete peníze využít a že se jedná o sbírku pro Goodbye Online parte. Poté je třeba počkat na schválení, které většinou trvá několik málo hodin od dodání všech potřebných informací.</p>
              {/* <DonioSearch /> */}

              {/* <div className="vysledky">

              </div> */}

              <p className="zalozitVlastni">Chcete založit vlastní sbírku? <a target="_blank" href="https://www.donio.cz/navrh">Zjistit více</a></p>
            </div>

            {(!selectedProject) ? 
              <div className="preskocit">
                <h2>Žádné peníze nevybírat a pokračovat do další sekce</h2>
                <Link to="/vytvorit-parte/osobni-info/">Přeskočit</Link>
              </div>
              :
              <div className="pokracovat">
                <h2>Pokračovat dál</h2>
                <Link to="/vytvorit-parte/osobni-info/">Pokračovat</Link>
              </div>
            }

          </div>
        :
        <div className="loadingScreen">
          <img src={Loading} alt="Načítání..." />
        </div>

        }
      </div>

      <div className="right"></div>
    </DonioInner>
  );
}

export default Donio;
