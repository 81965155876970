import React, { useCallback, useState, useRef } from "react";
import 'swiper/swiper-bundle.min.css';
import styled from "styled-components";
import { useDropzone } from 'react-dropzone';
import axios from "axios";
import PlusIcon from "../../assets/img/plus.svg";
import Loading from "../../assets/img/loadingTransparent.svg";


const PretahnoutFotkyInner = styled.div`
  form {
    margin-bottom: 80px;
    background: #FFFFFF;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='3' stroke-dasharray='5' stroke-dashoffset='95' stroke-linecap='butt'/%3e%3c/svg%3e");
    border-radius: 5px;
    height: 250px !important;
    position: relative;

    @media (max-width: 580px) {
      margin-bottom: 0;
    }

    &:hover {
      background: #f5f5f5;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='3' stroke-dasharray='5' stroke-dashoffset='95' stroke-linecap='butt'/%3e%3c/svg%3e");
    }

    .dropzone {
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: relative;

      div {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        span {
          font-family: Visuelt-Regular;
          font-size: 16px;
          color: #515151;
          text-align: center;
          display: block;
          
          span {
            text-decoration: underline;
          }
        }

        img {
          margin: 0 auto;
          margin-bottom: 15px;
          display: block;
        }
      }
    }
  }

  .loadingImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
  }
`;



const PretahnoutFotky = ({ jwt, galerieItems, uploadData, setGalleryItems }) => {
  let backendUrl = "";
  

  if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:1337'
  }

  if (process.env.NODE_ENV === 'production') {
    backendUrl = 'https://goodbye-backend.herokuapp.com'
  }


  const jwtRef = useRef();
  jwtRef.current = jwt;
  const galerieRef = useRef();
  galerieRef.current = galerieItems;
  const [isLoading, setIsLoading] = useState(false);


  const uploadSoubory = async (files) => {

    setIsLoading(true);

    let galerieFotky = galerieRef.current;
    let galerieFotkyDummy = galerieRef.current;

    files.forEach(el => {
      galerieFotkyDummy = galerieFotkyDummy.concat({
        dummy: true
      });
    });

    setGalleryItems(galerieFotkyDummy);


    axios.post(`${backendUrl}/upload`, files, {
      headers: {
        Authorization: `Bearer ${jwtRef.current}`
      },
    }).then(r => {
  


  
      let readyData = galerieFotky.concat(r.data);
  

      uploadData(jwtRef.current, {
        "gallery": readyData
      }).then(res => {

        setIsLoading(false);

      });  
    }).catch(e => console.log(e));
  }

  const onDrop = useCallback(async (acceptedFiles, jwt) => {



    if (acceptedFiles) {
      const formData = new FormData();

      await acceptedFiles.forEach(file => {
        formData.append("files", file, file.name);
      });


      await uploadSoubory(formData, jwt);
    }

    else {
      setIsLoading(false);
    }

    

  }, []);

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({accept: 'image/jpeg, image/png', onDrop});


  return (
    <PretahnoutFotkyInner>
      {(!isLoading) ? 
        <form>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <div>
              <img src={PlusIcon} alt="" />
              <span>Přetáhnout nebo <span>vybrat více fotek</span></span>
            </div>
          </div>
        </form>
      :
        <form>
          <div>
            <div>
              <img className="loadingImg" src={Loading} alt="" />
            </div>
          </div>
        </form>
      }

    </PretahnoutFotkyInner>
  );
}

export default PretahnoutFotky;
