import React, { useState, useRef } from "react";
import 'swiper/swiper-bundle.min.css';
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";

import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import cs from "date-fns/locale/cs"; // the locale you want
registerLocale("cs", cs); // register it with the name you want



const UpravitInner = styled.section`

`;



const UpravitInfoModal = ({ parteData, uploadData, jwt, displayError, setIsVisible}) => {

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors, watch, control } = useForm();
  const onSubmit = data => handleForm(data);
  const pass1 = useRef({});
  pass1.current = watch("pass1", "");

  const handleForm = async (data) => {
    setIsLoading(true);

    if ((data.deathDate - data.birthDate) > 0) {
      const dataToUpload = {
        "name": data.name,
        "city": data.city,
        "birthDate": data.birthDate,
        "deathDate": data.deathDate,
      };
  
      await uploadData(jwt, dataToUpload);

      setIsVisible(false);
      setIsLoading(false);
    }

    else {
      displayError("Datum smrti nemůže být dřív, než datum narození");
      setIsLoading(false);
    }
  }


  return (
    <UpravitInner>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label className={`pole ${errors.name && "inputError"}`}>
          <span>Jméno zesnulého</span>

          <input
            ref={register({ required: true })}
            type="text"
            name="name"
            defaultValue={(parteData.name) ? parteData.name : null}
          />
          
          {errors.name && <div className="errorMsg">Toto pole je povinné</div>}
        </label>

        <div className="formInner">
          <label className={`pole ${errors.birthDate && "inputError"}`}>
            <span className="label" >Datum narození zesnulého</span>

            <Controller
              control={control}
              rules={{ required: true }}
              name="birthDate"
              defaultValue={(parteData.birthDate) ? new Date(parteData.birthDate) : null}
              render={(props) => (
                <ReactDatePicker
                  placeholderText="Vyberte datum"
                  onChange={(e) => props.onChange(e)}
                  selected={props.value}
                  locale="cs"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  className="textInput"
                  dropdownMode="select"
                  dateFormat="dd. MM. yyyy"
                />
              )}
            />

            {errors.birthDate && <div className="errorMsg">Toto pole je povinné</div>}
          </label>

          <label className={`pole ${errors.deathDate && "inputError"}`}>
            <span className="label" >Datum úmrtí</span>

            <Controller
              control={control}
              rules={{ required: true }}
              name="deathDate"
              defaultValue={(parteData.deathDate) ? new Date(parteData.deathDate) : null}
              render={(props) => (
                <ReactDatePicker
                  placeholderText="Vyberte datum"
                  onChange={(e) => props.onChange(e)}
                  selected={props.value}
                  locale="cs"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  className="textInput"
                  dropdownMode="select"
                  dateFormat="dd. MM. yyyy"
                />
              )}
            />

            {errors.deathDate && <div className="errorMsg">Toto pole je povinné</div>}
          </label>
        </div>

        <label className={`pole ${errors.city && "inputError"}`}>
          <span>Město</span>
          <input
            ref={register({ required: true })}
            type="text"
            name="city"
            defaultValue={(parteData.city) ? parteData.city : null}
          />
          {errors.city && <div className="errorMsg">Toto pole je povinné</div>}
        </label>

        <div className="buttons">
          <input className="submit" type="submit" value={(isLoading) ? "Načítání..." : "Uložit"} />
        </div>
      </form>
    </UpravitInner>
  )
}


export default UpravitInfoModal;
