import React, { useEffect, useState, useRef } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import 'swiper/swiper-bundle.min.css';
import axios from "axios";
import { useCookies } from "react-cookie";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useForm } from "react-hook-form";
import Sha1 from "../../components/hash.js";
import EmailParte from "../../components/emails/EmailParte";
import { renderToString } from 'react-dom/server';



const OsobniInfoInner = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 770px) {
    display: block;
  }

  .right {
    width: 40%;
    overflow: hidden;
    position: relative;

    @media (max-width: 1200px) {
      width: calc(100% - 550px);
    }

    @media (max-width: 770px) {
      display: none;
    }

    .fotka {
      height: calc(100vh - 65px);
    }
  }

  .left {
    width: 60%;
    min-height: calc(100vh - 65px);

    @media (max-width: 1200px) {
      min-width: 0;
      width: calc(500px);
    }

    @media (max-width: 770px) {
      width: calc(100% - 50px);
      margin: 0 auto;
    }

    .formWrapper {
      max-width: 500px;
      margin-left: calc((100vw - 1150px)/2 + 15px);
      margin-top: 60px;
      padding-bottom: 90px;

      @media (max-width: 1200px) {
        margin-left: 50px;
      }

      @media (max-width: 770px) {
        margin: 0 auto;
        margin-top: 40px;
      }

      h1 {
        font-family: NY-Medium;
        font-size: 32px;
        color: #000000;

        @media (max-width: 490px) {
          font-size: 27px;
          margin-bottom: 20px;
        }
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #4D4D4D;
        line-height: 25px;
        margin-top: 25px;
        margin-bottom: 50px;

        @media (max-width: 490px) {
          margin-bottom: 35px;
        }
      }

      form {
        .pole {
          margin-top: 25px;
          display: block;
          width: calc(100%);

          .label {
            display: block;
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #6C6C6C;
            line-height: 25px;
            margin-bottom: 8px;
          }

          .textInput {
            display: block;
            width: calc(100% - 24px);
            background: #FFFFFF;
            border: 2px solid #D2D2D2;
            border-radius: 5px;
            padding: 10px;
            font-family: Visuelt-Regular;
            font-size: 16px;
            color: #000;
            line-height: 25px;

            &:focus {
              border: 2px solid #566FEF;
            }
          }
        }

        .inputError {
          .textInput {
            border: 2px solid #FF6060 !important;
          }

          .label {
            color: #FF6060;
          }
        }

        .errorMsg {
          color: #FF6060;
          margin-top: 10px;
        }

        .submitButton {
          font-family: Visuelt-Medium;
          font-size: 18px;
          color: #FFFFFF;
          text-align: center;
          background: #566FEF;
          border-radius: 5px;
          width: 240px;
          height: 50px;
          border: 0;
          border-radius: 5px;
          margin-top: 50px;
          cursor: pointer;
        }
      }
    }
  }
`;

const OsobniInfo = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['session_id']);
  const initParteId = cookies.session_id;

  const { register, handleSubmit, errors, watch } = useForm();

  const onSubmit = data => handleForm(data);

  const pass1 = useRef({});
  pass1.current = watch("pass1", "");

  const handleForm = (data) => {
    setIsLoading(true);

    const { creatorName, email, pass1, pass2 } = data;

    let backendUrl = "";

    if (process.env.NODE_ENV === 'development') {
      backendUrl = 'http://localhost:1337'
    }
    
    if (process.env.NODE_ENV === 'production') {
      backendUrl = 'https://goodbye-backend.herokuapp.com'
    }

    // napojujeme se na API
    axios.post(`${backendUrl}/auth/local`, {
      identifier: 'app@goodbye.cz',
      password: 'Good.Bye123',
    }).then((res) => {
      const jwt = res.data.jwt;

      axios.get(`${backendUrl}/partes/id/${initParteId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`
        },
      }).then((res) => {
        axios.put(`${backendUrl}/partes/${res.data.id}`, {
            "creatorName": creatorName,
            "email": email,
            "password": Sha1.hash(pass1, {})
          }, {
          headers: {
            Authorization: `Bearer ${jwt}`
          },
        }).then((res) => {
          
          // poslat mail boi



          // email pro zákazníka
          axios.post(`${backendUrl}/poslimail`, {
            "to": email,
            "from": 'podpora@goodbye.cz',
            "subject": `Úspěšná registrace, Online parte - Goodbye.cz`,
            "text": `Vaše registrace proběhla úspěšně. Děkujeme za registraci na naší stánce Goodbye.cz. Váš e-mail a heslo, které jste zadali, budou fungovat jako přihlašovací údaje, pomocí kterých se k parte můžete kdykoliv vrátit a cokoliv v něm upravit či doplnit. Pokud byste potřebovali s něčím pomoci nebo se chtěli na cokoliv zeptat, neváhejte se na nás obrátit na tomto e-mailu nebo na zákaznické lince 315 558 136. Rádi Vám pomůžeme.`,
            "html": renderToString(<EmailParte />),
          },
          {
            headers: {
              "Authorization": `Bearer ${jwt}`,
              "Content-Type": "application/json"
            },
          }).then(() => {
            navigate("/vytvorit-parte/upravit/");
            setIsLoading(false);

          }).catch(e => console.log(e));

          
        }).catch(e => console.log(e));

      }).catch(e => console.log(e));
    });
  }

  useEffect(() => {
    if (!cookies.session_id || cookies.session_id === "") {
      if (typeof window !== 'undefined') {
        window.location = '/vytvorit-parte'
      }
    }
  }, []);


  return (
    <StaticQuery
      query = {graphql`
        query {
          image: file(relativePath: { eq: "parteImage.png" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <OsobniInfoInner>
          <div className="left">
            <div className="formWrapper">
              <h1>Informace o vás</h1>
              <p>Pomocí těchto údajů se budete moci přihlásit a parte zpětně upravit či doplnit.</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <label className={`pole ${errors.creatorName && "inputError"}`}>
                  <span className="label">Vaše jméno</span>
                  <input className="textInput" ref={register({ required: true })} name="creatorName" type="text" />
                  {errors.creatorName && <div className="errorMsg">Toto pole je povinné</div>}
                </label>
                <div>
                  <label className={`pole ${errors.email && "inputError"}`}>
                    <span className="label">E-mail</span>
                    <input className="textInput" ref={register({ required: true })} name="email" type="email" />
                  {errors.email && <div className="errorMsg">Toto pole je povinné</div>}
                  </label>
                  <label className={`pole ${errors.pass1 && "inputError"}`}>
                    <span className="label">Vytvořit heslo</span>
                    <input className="textInput" ref={register({ required: true })} name="pass1" type="password" />
                  {errors.pass1 && <div className="errorMsg">Toto pole je povinné</div>}
                  </label>
                </div>
                <label className={`pole ${errors.pass2 && "inputError"}`}>
                  <span className="label">Zopakovat heslo</span>
                  <input className="textInput" ref={register({
                    validate: value => value === pass1.current || "Hesla se neshodují"
                  })} name="pass2" type="password" />
                  {errors.pass2 && <div className="errorMsg">Hesla se neshodují</div>}
                </label>
                <input className="submitButton" type="submit" value={(isLoading) ? "Načítání..." : "Začít tvořit"} />
              </form>
            </div>
          </div>
          <div className="right">
            <Img className="fotka" alt="" fluid={data.image.childImageSharp.fluid} />
          </div>
        </OsobniInfoInner>
      )}
    />
  );
};


export default OsobniInfo;
