import React from "react";
import { Router, Redirect } from "@reach/router";
import Layout from "../components/Layout";
import Upravit from "../components/parte/Upravit";
import VytvoritNove from "../components/parte/VytvoritNove";
import OsobniInfo from "../components/parte/OsobniInfo";
import Donio from "../components/parte/Donio";
import NotFound from "../components/parte/NotFound";
import SEO from "../components/SEO";



const ParteApp = () => {
  return (
    <Layout simpleHeader noFooter>
      <SEO noindex />
      <Router basepath="/vytvorit-parte/">
        <Upravit path="/upravit/" />
        <Donio path="/donio/" />
        <OsobniInfo path="/osobni-info/" />
        <VytvoritNove path="/" />
        <NotFound default />
      </Router>
    </Layout>
  )
}

export default ParteApp