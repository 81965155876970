import React, { useState } from "react";
import { navigate } from "gatsby";
import 'swiper/swiper-bundle.min.css';
import styled from "styled-components";
import { useCookies } from "react-cookie";
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import cs from "date-fns/locale/cs"; // the locale you want
registerLocale("cs", cs); // register it with the name you want



const UpravitInner = styled.section`
  width: calc(100% - 50px);
  margin: 0 auto;

  h1 {
    font-family: Visuelt-Medium;
    font-size: 20px;
    color: #000000;
    margin-bottom: 30px;
  }

  .buttons {
    button {
      background: #FF6060;
      color: #fff;
      font-family: Visuelt-Medium;
      text-align: center;
      border: 2px solid #FF6060;
      border-radius: 5px;
      font-size: 18px;
      padding: 11px 0;
      width: 130px;
      cursor: pointer;

      @media (max-width: 370px) {
        width: 110px;
        padding: 9px 0;
      }

      &:hover {
        background: #da4646;
        border: 2px solid #da4646;
      }
    }

    .zpetButton {
      background: #FFFFFF;
      border: 2px solid #000000;
      color: #000000;
      cursor: pointer;
      margin-left: 25px;

      @media (max-width: 370px) {
        margin-left: 20px;
      }

      &:hover {
        background: #f5f5f5;
        border: 2px solid #000000;
      }
    }
  }
`;



const VymazatParteModal = ({ jwt, parteData, setIsVisible}) => {


  // vsechno good
      
  let backendUrl = "";

  if (process.env.NODE_ENV === 'development') {
    backendUrl = 'http://localhost:1337'
  }
  
  if (process.env.NODE_ENV === 'production') {
    backendUrl = 'https://goodbye-backend.herokuapp.com'
  }

  const [cookies, setCookie, removeCookie] = useCookies(['session_id']);
  const [isLoading, setIsLoading] = useState(false);
  
  const deleteParte = () => {
    axios.delete(`${backendUrl}/partes/${parteData._id}`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      },
    }).then((res) => {
      setCookie("session_id", "", { path: "/" })
      navigate("/parte/");
    });
  }


  return (
    <UpravitInner>
      <h1>Chcete opravdu parte vymazat?</h1>
      <div className="buttons">
        <button onClick={() => deleteParte()}>{isLoading ? "Načítání" : "Vymazat"}</button>
        <button className="zpetButton" onClick={() => setIsVisible(false)}>Zpět</button>
      </div>
    </UpravitInner>
  )
}


export default VymazatParteModal;
