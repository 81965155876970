import React, { useState, useRef } from "react";
import 'swiper/swiper-bundle.min.css';
import { useForm } from "react-hook-form";
import styled from "styled-components";

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import cs from "date-fns/locale/cs"; // the locale you want
registerLocale("cs", cs); // register it with the name you want



const UpravitInner = styled.section`

`;



const UpravitInfoModal = ({ parteData, uploadData, jwt, displayError, setIsVisible }) => {

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, errors, watch, control } = useForm();
  const onSubmit = data => handleForm(data);
  const pass1 = useRef({});
  pass1.current = watch("pass1", "");

  const handleForm = async (data) => {
    setIsLoading(true);

    let dataToUpload = {
      "funeralAddress": data.funeralAddress,
      "funeralDescription": data.funeralDescription,
    };

    await uploadData(jwt, dataToUpload);

    setIsVisible(false);
    setIsLoading(false);

  }


  return (
    <UpravitInner>
      <form onSubmit={handleSubmit(onSubmit)}>

        <label className={`pole ${errors.funeralDescription && "inputError"}`}>
          <span>Informace o pohřbu (místo, čas, detaily, atd.)</span>

          <textarea
            defaultValue={(parteData.funeralDescription) ? parteData.funeralDescription : null}
            ref={register({ required: true })}
            name="funeralDescription"
          />

          {errors.funeralDescription && <div className="errorMsg">Toto pole je povinné</div>}
        </label>

        <label className={`pole ${errors.funeralAddress && "inputError"}`}>
          <span>Adresa (pokud je k dispozici)</span>

          <input
            ref={register()}
            type="text"
            name="funeralAddress"
            defaultValue={(parteData.funeralAddress) ? parteData.funeralAddress : null}
          />
        </label>

        {/* tady přidat autosuggestion na polohu */}

        <div className="buttons">
          <input className="submit" type="submit" value={(isLoading) ? "Načítání..." : "Uložit"} />
        </div>
      </form>
    </UpravitInner>
  )
}


export default UpravitInfoModal;
