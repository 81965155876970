import React from "react";
import { navigate } from "gatsby";
import LoadingImg from "../../assets/img/loading.svg";
import styled from "styled-components";


const LoadingScreen = styled.div`
  background: rgb(241, 242, 243);
  min-height: calc(100vh - 65px);

  img {
    margin: 0 auto;
    padding-top: 30px;
    display: block;
  }
`;

const NotFound = () => {
  navigate("/404/");

  return (
    <LoadingScreen>
      <img src={LoadingImg} alt="Načítání..." />
    </LoadingScreen>
  );
};


export default NotFound;
